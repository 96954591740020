import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Popover, useTheme } from "@mui/material";
import { useTranslations } from "@recare/translations";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import RSButton from "ds/components/RSButton";
import { HorizontalDivider, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding, sizing } from "ds/materials/metrics";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_32,
  Subheading,
} from "ds/materials/typography";
import {
  PROVIDER_SEARCH_LOGIN_MODAL,
  PROVIDER_SEARCH_SIGNUP_MODAL,
  ProviderRegistrationInfo,
} from "dsl/ecosystems/ProviderSearchLoginModal/shared";

export const RegistrationPopover = ({
  anchor,
  onClose,
  open,
}: {
  anchor: Element | null;
  onClose: () => void;
  open: boolean;
}) => {
  const translations = useTranslations();
  const { setLoginModal } = useProviderSearchContext();
  const theme = useTheme();

  return (
    <Popover
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{ sx: { margin: margin(1, 0, 0) } }}
    >
      <VerticalLayout
        width={sizing(42)}
        padding={padding(3, 4)}
        borderRadius={dp(8)}
      >
        <VerticalLayout aligned padding={padding(1, 4)}>
          <InfoOutlinedIcon
            htmlColor={theme.palette.primary.main}
            sx={{ fontSize: FONT_SIZE_32 }}
          />
          <VerticalLayout
            style={{ alignItems: "flex-start", margin: margin(2, 0, 1) }}
          >
            <Subheading margin={margin(0, 0, 1)}>
              {translations.providersearch.loginDropDown.title}
            </Subheading>
            <ul style={{ margin: margin(0), padding: padding(0, 0, 0, 2.5) }}>
              <li>
                <Body as="p" margin={margin(0)} fontSize={FONT_SIZE_14}>
                  {translations.providersearch.loginDropDown.bulletOne}
                </Body>
              </li>
              <li>
                <Body as="p" margin={margin(0)} fontSize={FONT_SIZE_14}>
                  {translations.providersearch.loginDropDown.bulletTwo}
                </Body>
              </li>
              <li>
                <Body as="p" margin={margin(0)} fontSize={FONT_SIZE_14}>
                  {translations.providersearch.loginDropDown.bulletThreee}
                </Body>
              </li>
              <li>
                <Body as="p" margin={margin(0)} fontSize={FONT_SIZE_14}>
                  {translations.providersearch.loginDropDown.bulletFour}
                </Body>
              </li>
            </ul>
          </VerticalLayout>
        </VerticalLayout>
        <RSButton
          ariaProps={{
            "aria-haspopup": "dialog",
          }}
          style={{ width: "100%", margin: margin(1, 0) }}
          id="login"
          onClick={() => {
            setLoginModal(PROVIDER_SEARCH_LOGIN_MODAL);
            onClose();
          }}
          loading="na"
          variant="outlined"
          color="primary"
        >
          {translations.providersearch.loginDropDown.loginButton}
        </RSButton>
        <RSButton
          ariaProps={{
            "aria-haspopup": "dialog",
          }}
          style={{ width: "100%", margin: margin(1, 0) }}
          id="signup"
          onClick={() => {
            setLoginModal(PROVIDER_SEARCH_SIGNUP_MODAL);
            onClose();
          }}
          loading="na"
          variant="outlined"
          color="primary"
        >
          {translations.providersearch.loginDropDown.createAccountButton}
        </RSButton>
        <HorizontalDivider
          color={theme.palette.neutrals.medium_grey}
          style={{ margin: margin(2, 0, 1) }}
        />
        <ProviderRegistrationInfo />
      </VerticalLayout>
    </Popover>
  );
};
