import {
  ACCOUNT_TYPE_STAFF,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "@recare/core/consts";
import { useSafeState } from "@recare/core/hooks";
import api from "@recare/core/model/api";
import { importPrivateKey } from "@recare/core/model/crypto";
import {
  decryptMessage,
  decryptSessionKey,
  generateExtraSessionKey,
} from "@recare/core/model/crypto/cryptoService";
import { useActivityContext } from "@recare/core/model/utils/browser/ActivitySingleton";
import { computeSealdDisplayId } from "@recare/core/seald";
import { useSealdContext } from "@recare/core/seald/SealdContext";
import { decryptFromSession } from "@recare/core/seald/sessions";
import {
  Account,
  AuctionRequest,
  EncryptionContext as EncryptionContextType,
  Event,
  EventContext,
  PendingSessionKey,
  QueryProgress,
  SessionKey,
  TrackEventFn,
} from "@recare/core/types";
import { useCreateEncryptionContext } from "apollo/hooks/mutations";
import { useOnPendingSessionKey } from "apollo/hooks/subscriptions";
import { UpdateRequestSessionKeysMutation } from "apollo/mutations";
import { useEnvContext } from "context/EnvContext";
import { getUnixTime } from "date-fns";
import SpinnerPage from "ds/components/Spinner";
import { EncryptionContext, EncryptionKeyContext } from "dsl/atoms/Contexts";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTracking } from "react-tracking";
import {
  ConnecterWithPrivateKey,
  usePrivateKey,
} from "reduxentities/selectors";
import {
  useLoggedCareprovider,
  useLoggedCareseeker,
} from "reduxentities/selectors/hooks";
import {
  fetchKeysdecryptKey,
  generateSessionKeys,
  isEncryptionAvailableForUser,
} from "./shared";

async function decryptEventContext(
  context: EventContext,
  decryptedSessionKey: CryptoKey,
) {
  if (
    !context.file?.encrypted_name?.content ||
    !context.file?.encrypted_name?.iv
  )
    return context;

  try {
    const decrypted = await decryptMessage({
      message: context.file.encrypted_name.content,
      sessionKey: decryptedSessionKey,
      iv: context.file.encrypted_name.iv,
    });

    return {
      ...context,
      file: {
        ...context.file,
        encrypted_name: {
          ...context.file.encrypted_name,
          decrypted,
        },
      },
    };
  } catch (e) {
    console.error("Could not decrypt event context", e);
  }
}

/** @deprecated To be removed when old encrytion gets decommissioned */
export async function decryptEvent(
  event: Event,
  decryptedSessionKey: CryptoKey,
  trackEvent: TrackEventFn,
) {
  if (!event.context) return event;

  try {
    let message = event.context.message_iv
      ? await decryptMessage({
          message: event?.context?.message || "",
          sessionKey: decryptedSessionKey,
          iv: event.context.message_iv,
        })
      : event.context.message;

    if (!message && event.context.seald_message) {
      message = await decryptFromSession({
        encryptedMessage: event.context.seald_message,
        context: "event message",
      });
    }

    return {
      ...event,
      context: await decryptEventContext(
        {
          ...event.context,
          message,
          decrypted: true,
        },
        decryptedSessionKey,
      ),
    };
  } catch (error) {
    console.log(
      `%c Could not deserialize message ${error}`,
      "background: red; color: white",
    );

    trackEvent({
      name: TRACK_EVENTS.MESSAGE_DESERIALIZATION_ERROR,
      event_id: event.id?.toString(),
    });
  }

  return event;
}

export async function decryptSealdEvent(
  event: Event,
  trackEvent: TrackEventFn,
) {
  if (!event.context) return event;

  try {
    const sealdDecryptedMessage = await decryptFromSession({
      encryptedMessage: event.context.seald_message,
      context: "event message",
    });

    return {
      ...event,
      context: {
        ...event.context,
        message: sealdDecryptedMessage ?? event.context.message,
        decrypted: !!sealdDecryptedMessage,
      },
    };
  } catch (error) {
    console.error(
      `%c Could not deserialize message ${error}`,
      "background: red; color: white",
    );

    trackEvent({
      name: TRACK_EVENTS.MESSAGE_DESERIALIZATION_ERROR,
      event_id: event.id?.toString(),
    });
  }

  return event;
}

async function decryptSealdEvents(
  events: Array<Event>,
  trackEvent: TrackEventFn,
  setDecryptedEvents: any,
) {
  if (events) {
    const decrypted = await Promise.all(
      events.map((event) => decryptSealdEvent(event, trackEvent)),
    );

    setDecryptedEvents(decrypted);
  }
}

/** @deprecated To be removed when old encrytion gets decommissioned */
async function fetchKeysdecryptEvent(
  decryptedSessionKey: any,
  events: Array<Event>,
  trackEvent: TrackEventFn,
  setDecryptedEvents: any,
) {
  if (!decryptedSessionKey) return;
  if (events) {
    const decrypted = await Promise.all(
      events.map(async (e) => decryptEvent(e, decryptedSessionKey, trackEvent)),
    );

    setDecryptedEvents(decrypted);
  }
}

function PendingEventDecryption() {
  const { trackEvent } = useTracking();

  // If the decryption fails, the user ends up in this view forever.
  // It should never happen.
  useEffect(() => {
    const timer = setTimeout(() => {
      trackEvent({ name: TRACK_EVENTS.EVENT_DECRYPTION_ERROR });
      console.error("Event decryption error");
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  });

  return <SpinnerPage id="event_decryption" />;
}

/** @deprecated To be removed when old encrytion gets decommissioned */
const FetchKeysHOC = ({
  account,
  accounts,
  auctionRequest,
  children,
  context,
  events,
  privateKey,
  resourceId,
  sessionKey,
  updateSessionKey,
}: {
  account: Account;
  accounts: Array<Account> | null;
  auctionRequest: AuctionRequest;
  children: React.ReactNode;
  context: AnyObject;
  events: Array<Event>;
  privateKey: string;
  resourceId: number;
  sessionKey: SessionKey | null;
  updateSessionKey: (s: any) => void;
}) => {
  const [accessDenied, setAccessDenied] = useSafeState<boolean>(false);
  const [decryptedSessionKey, setDecryptedSessionKey] = useSafeState<
    CryptoKey | null | undefined
  >();
  const { trackEvent } = useTracking();

  const [decryptedEvents, setDecryptedEvents] = useSafeState<Event[] | null>(
    null,
  );

  useLayoutEffect(() => {
    generateSessionKeys(
      trackEvent,
      sessionKey,
      accounts,
      context,
      account,
      updateSessionKey,
    );
  }, [resourceId]);

  useLayoutEffect(() => {
    fetchKeysdecryptKey({
      context,
      privateKey,
      sessionKey,
      setAccessDenied,
      setDecryptedSessionKey,
      trackEvent,
    });
  }, [resourceId, sessionKey]);

  useLayoutEffect(() => {
    fetchKeysdecryptEvent(
      decryptedSessionKey,
      events,
      trackEvent,
      setDecryptedEvents,
    );
  }, [decryptedSessionKey, events.length]);

  useEffect(() => {
    if (decryptedEvents && events && decryptedEvents.length != events.length) {
      decryptedEvents.push(events[events.length - 1]);
    }
  });

  const eventsDecrypted = decryptedEvents != null;

  if (!accessDenied && !eventsDecrypted) return <PendingEventDecryption />;
  return (
    <EncryptionKeyContext.Provider value={decryptedSessionKey || null}>
      <EncryptionContext.Provider
        value={{
          userSupportsEncryption: !accessDenied,
          hasEncryption: auctionRequest.is_provider_search_request
            ? !!auctionRequest.seald_encryption_context
            : true,
          events: decryptedEvents,
          account,
        }}
      >
        {children}
      </EncryptionContext.Provider>
    </EncryptionKeyContext.Provider>
  );
};

const SealdEventsProvider = ({
  account,
  children,
  events,
  sealdEncryptionContext,
}: {
  account: Account;
  children: React.ReactNode;
  events: Array<Event>;
  sealdEncryptionContext: EncryptionContextType | undefined;
}) => {
  const { trackEvent } = useTracking();
  const hasSealdEncryptionContext = !!sealdEncryptionContext;
  const [decryptedEvents, setDecryptedEvents] = useSafeState<Event[] | null>(
    null,
  );

  useLayoutEffect(() => {
    if (hasSealdEncryptionContext)
      decryptSealdEvents(events, trackEvent, setDecryptedEvents);
  }, [events.length]);

  useEffect(() => {
    if (decryptedEvents && events && decryptedEvents.length != events.length) {
      decryptedEvents.push(events[events.length - 1]);
    }
  });

  const eventsDecrypted = decryptedEvents != null;

  if (!eventsDecrypted) return <PendingEventDecryption />;
  return (
    <EncryptionKeyContext.Provider value={null}>
      <EncryptionContext.Provider
        value={{
          userSupportsEncryption: true,
          hasEncryption: hasSealdEncryptionContext,
          events: decryptedEvents,
          account,
          isSealdOnly: true,
        }}
      >
        {children}
      </EncryptionContext.Provider>
    </EncryptionKeyContext.Provider>
  );
};

export function useDecryptPatientEvents({
  decryptedPatientSessionKey,
  events,
}: {
  decryptedPatientSessionKey: CryptoKey | null;
  events: Array<Event>;
}): Array<Event> {
  const { trackEvent } = useTracking();

  const [decryptedEvents, setDecryptedEvents] = useSafeState<Array<Event>>([]);

  useLayoutEffect(() => {
    // For staff accounts
    if (decryptedPatientSessionKey == null) {
      setDecryptedEvents(events);
      return;
    }

    fetchKeysdecryptEvent(
      decryptedPatientSessionKey,
      events,
      trackEvent,
      setDecryptedEvents,
    );
  }, [decryptedPatientSessionKey, events.length]);

  useEffect(() => {
    if (decryptedEvents && events && decryptedEvents.length != events.length) {
      decryptedEvents.push(events[events.length - 1]);
    }
  });

  return decryptedEvents || [];
}

function getPendingKeyType(key: PendingSessionKey) {
  if (key.patient_id) return "patient";
  if (key.auction_request_id) return "request";
  if (key.careprovider_id) return "provider";
  console.error("Could not find pending key type", key);
  return "undefined";
}

async function restorePendingKey({
  key,
  privateKey,
  trackEvent,
}: {
  key: PendingSessionKey;
  privateKey: any;
  trackEvent: TrackEventFn;
}) {
  const errorContext: [string, AnyObject] = [
    "Pending Key decryption",
    {
      destination_account_id: key.account_id,
      auction_request_id: key.auction_request_id,
      patient_id: key.patient_id,
      careprovider_id: key.careprovider_id,
      careseeker_id: key.careseeker_id,
      pending_key_type: getPendingKeyType(key),
      status: "error",
    },
  ];
  try {
    const importedPrivateKey = await importPrivateKey(privateKey);
    if (!importedPrivateKey) {
      trackEvent({
        name: TRACK_EVENTS.IMPORTED_PRIVATE_KEY,
        error: JSON.stringify(errorContext),
      });

      return;
    }
    const decrypted = await decryptSessionKey(
      key?.user_session_key as unknown as CryptoKey,
      importedPrivateKey,
      key?.algorithm,
    );

    if (!decrypted) {
      trackEvent({
        name: TRACK_EVENTS.DECRYPT_SESSION_KEY,
        error: JSON.stringify(errorContext),
      });

      return;
    }

    const generated = await generateExtraSessionKey(decrypted, {
      id: key.account_id,
      public_key: key.account_public_key as unknown as CryptoKey,
    });

    try {
      await api.crypto.updatePendingSessionKey({
        ...generated,
        auction_request_id: key.auction_request_id,
        algorithm: null,
        patient_id: key.patient_id,
        careprovider_id: key.careprovider_id,
        careseeker_id: key.careseeker_id,
        updated_at: getUnixTime(new Date()),
      });
    } catch (e) {
      console.groupCollapsed("Could not update pending session key");
      console.error("Could not update pending session key", e);
      console.groupEnd();
    }

    trackEvent({
      name: TRACK_EVENTS.PENDING_KEY_DECRYPTION,
      destination_account_id: key.account_id,
      pending_key_type: getPendingKeyType(key),
      status: "success",
    });
  } catch (err) {
    trackEvent({
      name: TRACK_EVENTS.MESSAGE_DESERIALIZATION_ERROR,
      error: JSON.stringify(errorContext),
    });

    console.error("error restoring pending session keys - ", err);
  }
}

async function handlePendingKeys(
  keys: Array<PendingSessionKey> | null | undefined,
  privateKey: any,
  trackEvent: TrackEventFn,
  delay?: number,
) {
  if (keys == null) return;
  for (let i = 0; i < keys.length; i += 1) {
    // we are aware waiting in  each iteration
    // prevents some of the async advantages
    // however, this is a CPU heavy operation and
    // we want to make sure only one is run at a
    // time, to not overload the CPU
    // eslint-disable-next-line no-await-in-loop
    await restorePendingKey({ key: keys[i], privateKey, trackEvent });
    // eslint-disable-next-line no-await-in-loop
    await new Promise((t) => setTimeout(t, delay != null ? delay : 500));
  }
}

export function useGetPendingKeys() {
  const { trackEvent } = useTracking();

  const privateKey = usePrivateKey();
  const [done, setDone] = useSafeState(false);
  const [manualDone, setManualDone] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const processPendingKeys = useCallback(async () => {
    if (privateKey != null) {
      api.crypto
        .getPendingKeys()
        .then((keys) => handlePendingKeys(keys, privateKey, trackEvent, 0));
    }
  }, [privateKey]);

  useEffect(() => {
    processPendingKeys().then(() => setDone(true));
  }, [processPendingKeys, setDone]);

  const getPendingKeys = useCallback(async () => {
    return new Promise<void>((resolve) => {
      setManualDone(QUERY_PROGRESS_PENDING);
      processPendingKeys()
        .then(() => setManualDone(QUERY_PROGRESS_SUCCEED))
        .catch(() => setManualDone(QUERY_PROGRESS_FAILED))
        .finally(() => resolve());
    });
  }, [processPendingKeys, setManualDone]);

  return { done, manualDone, getPendingKeys };
}

export function useRestorePendingKeys(privateKey: AnyObject) {
  const { trackEvent } = useTracking();
  const { active } = useActivityContext();
  const [pendingSessionKeyFromSubscription] = useOnPendingSessionKey();

  useEffect(() => {
    if (privateKey && pendingSessionKeyFromSubscription) {
      handlePendingKeys(
        [pendingSessionKeyFromSubscription],
        privateKey,
        trackEvent,
      );
      console.log("PSK restored through WS");
      trackEvent({ name: TRACK_EVENTS.PSK_RESTORED_THROUGH_WS });
    }
  }, [pendingSessionKeyFromSubscription, privateKey]);

  useEffect(() => {
    if (!privateKey) return;

    async function regenerateSessionKeysFromPending() {
      api.crypto
        .getPendingKeys()
        .then((keys) => handlePendingKeys(keys, privateKey, trackEvent));
    }

    // Run it on mount
    regenerateSessionKeysFromPending();

    // We have no way to "push" at the moment.
    // So we pull every X minutes
    const intervalToPullInMinutes = active ? 2 : 60;
    const id = setInterval(
      () => {
        regenerateSessionKeysFromPending();
      },
      intervalToPullInMinutes * 60 * 1000,
    );

    /* eslint-disable consistent-return */
    return () => {
      clearInterval(id);
    };
  }, [privateKey, active]);
}

function useAuctionRequestSealdSession(
  auctionRequest: AuctionRequest | null | undefined,
) {
  const hasEncryptionContext = !!auctionRequest?.has_seald_encryption_context;
  const [isProcessed, setIsProcessed] = useState(hasEncryptionContext);
  const [createEncryptionContext] = useCreateEncryptionContext();
  const createSealdSession = useSealdContext()?.createSealdSession;
  const envContext = useEnvContext();
  const loggedCareseeker = useLoggedCareseeker();
  const loggedCareprovider = useLoggedCareprovider();

  const loggedGroupDisplayId = loggedCareseeker?.id
    ? computeSealdDisplayId({
        id: loggedCareseeker.id,
        type: "careseeker",
        envContext,
      })
    : loggedCareprovider?.id
    ? computeSealdDisplayId({
        id: loggedCareprovider.id,
        type: "careprovider",
        envContext,
      })
    : null;

  const careseekerDisplayId = auctionRequest?.auction?.patient?.careseeker?.id
    ? computeSealdDisplayId({
        id: auctionRequest.auction.patient.careseeker.id,
        type: "careseeker",
        envContext,
      })
    : undefined;
  const careproviderDisplayId = auctionRequest?.careprovider_id
    ? computeSealdDisplayId({
        id: auctionRequest.careprovider_id,
        type: "careprovider",
        envContext,
      })
    : undefined;

  useEffect(() => {
    if (
      !hasEncryptionContext &&
      careseekerDisplayId &&
      careproviderDisplayId &&
      loggedGroupDisplayId
    ) {
      createSealdSession?.({
        entity: auctionRequest,
        entityType: "auction_request",
        entityUsersDisplayIds: [
          careseekerDisplayId,
          careproviderDisplayId,
        ].truthy(),
        createEncryptionContext,
        createSealdAccess: api.crypto.createSealdAccess,
        checkSealdGroupAccess: api.crypto.checkSealdGroupAccess,
        loggedGroupDisplayId,
      })
        .then(() => {
          setIsProcessed(true);
        })
        .catch(console.error);
    }
  }, [
    careseekerDisplayId,
    careproviderDisplayId,
    hasEncryptionContext,
    loggedGroupDisplayId,
  ]);
  return isProcessed;
}

const SealdProcessedMark = ({
  auctionRequestId,
  isProcessed,
}: {
  auctionRequestId: number;
  isProcessed: boolean;
}) => (
  <input
    type="hidden"
    id={`request_${auctionRequestId}_seald_processed`}
    name="request_seald_processed"
    value={String(isProcessed)}
  />
);

type EncryptionProviderProps = {
  auctionRequest: AuctionRequest | null | undefined;
  children: React.ReactNode;
  events: Array<Event>;
  isSealdOnly?: boolean;
};

export const EncryptionProvider = ConnecterWithPrivateKey(function Encryption({
  account,
  auctionRequest,
  children,
  events,
  isSealdOnly = false,
  privateKey,
}: EncryptionProviderProps & {
  account: Account;
  privateKey: any;
}) {
  const [sessionKey, setSessionKey] = useState<SessionKey | undefined>(
    auctionRequest?.session_key,
  );
  const hasEncryption =
    (!isSealdOnly && Boolean(auctionRequest?.messenger_available)) ||
    Boolean(auctionRequest?.has_seald_encryption_context);
  let isProcessed = useAuctionRequestSealdSession(auctionRequest);
  isProcessed =
    (isSealdOnly || auctionRequest?.messenger_available) &&
    !auctionRequest?.has_seald_encryption_context
      ? isProcessed
      : true;

  if (!auctionRequest) return null;
  if (!isProcessed) return <SpinnerPage id="seald_request_processed" />;

  const context = { auction_request_id: auctionRequest.id };
  const accounts = auctionRequest?.accounts?.filter(
    (acc) => isSealdOnly || !!acc?.public_key?.length,
  );

  if (!hasEncryption)
    return (
      <EncryptionKeyContext.Provider value={null}>
        <EncryptionContext.Provider
          value={{
            account,
            userSupportsEncryption: false,
            hasEncryption: false,
            events,
            isSealdOnly,
          }}
        >
          <SealdProcessedMark
            auctionRequestId={auctionRequest.id}
            isProcessed={isProcessed}
          />
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );

  const [userSupportsEncryption, reason] = isEncryptionAvailableForUser(
    accounts,
    account,
    privateKey,
    auctionRequest,
    isSealdOnly,
  );

  if (!userSupportsEncryption)
    return (
      <EncryptionKeyContext.Provider value={null}>
        <EncryptionContext.Provider
          value={{
            account,
            userSupportsEncryption:
              account?.account_type === ACCOUNT_TYPE_STAFF || false,
            hasEncryption: true,
            encryptionError: reason || undefined,
            events,
            isSealdOnly,
          }}
        >
          <SealdProcessedMark
            auctionRequestId={auctionRequest.id}
            isProcessed={isProcessed}
          />
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );

  if (isSealdOnly) {
    return (
      <SealdEventsProvider
        account={account}
        events={events}
        sealdEncryptionContext={auctionRequest.seald_encryption_context}
      >
        <SealdProcessedMark
          auctionRequestId={auctionRequest.id}
          isProcessed={isProcessed}
        />
        {children}
      </SealdEventsProvider>
    );
  }

  return (
    <UpdateRequestSessionKeysMutation
      onCompleted={({ sessionKeys }) => {
        const sessionKeyMap = sessionKeys.find(
          (key: SessionKey) => key.account_id === account.id,
        );
        setSessionKey(sessionKeyMap);
      }}
      auctionRequestId={auctionRequest.id}
    >
      {(updateSessionKey: any) => (
        <FetchKeysHOC
          accounts={accounts || null}
          auctionRequest={auctionRequest}
          resourceId={auctionRequest.id}
          context={context}
          sessionKey={sessionKey || null}
          updateSessionKey={updateSessionKey}
          privateKey={privateKey}
          account={account}
          events={events}
        >
          <SealdProcessedMark
            auctionRequestId={auctionRequest.id}
            isProcessed={isProcessed}
          />
          {children}
        </FetchKeysHOC>
      )}
    </UpdateRequestSessionKeysMutation>
  );
}) as unknown as React.ComponentType<EncryptionProviderProps>;
