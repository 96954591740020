import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper } from "@mui/material";
import { activateEnvSwitch, isCI } from "@recare/core/model/utils/featureFlags";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { Z_INDEX_APP_BAR, dp, padding } from "ds/materials/metrics";
import BlinkingEnv from "dsl/atoms/BlinkingEnv";
import { usePrint } from "dsl/atoms/Contexts";
import { EnvSelect } from "dsl/atoms/EnvSelect";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useState } from "react";

export const ProviderSearchEnvSwitch = () => {
  const [show, setShow] = useState(false);
  const { isMobile, isTablet } = useMedia();
  const print = usePrint();

  if (!activateEnvSwitch || isCI || print) return null;

  return (
    <Paper
      sx={{
        position: "fixed",
        zIndex: Z_INDEX_APP_BAR + 1,
        top: isMobile ? undefined : 20,
        bottom: isMobile ? 20 : undefined,
        right: isMobile ? 20 : isTablet ? "28vw" : "50vw",
        "@media print": {
          display: "none",
        },
      }}
    >
      <HorizontalLayout>
        {show && (
          <div style={{ padding: padding(2) }}>
            <EnvSelect
              style={{ width: dp(200) }}
              onChange={() => {
                window.location.reload();
              }}
            />
          </div>
        )}
        <VerticalLayout justify={isMobile ? "flex-end" : "flex-start"}>
          <IconButton onClick={() => setShow((s) => !s)}>
            {show ? <CloseIcon /> : <BlinkingEnv />}
          </IconButton>
        </VerticalLayout>
      </HorizontalLayout>
    </Paper>
  );
};
