import { useTranslations } from "@recare/translations";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import {
  AppBarProviderSearch,
  LeftPart,
  LogoWrapper,
} from "ds/components/AppBar";
import { sizing } from "ds/materials/metrics";
import Image from "dsl/atoms/Image";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useProvidersearchNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import SkipLink from "../A11ySkipLink";
import { NavBarActions } from "./NavBarActions";

const ProviderSearchLogo = () => {
  const { goToListing } = useProvidersearchNavigationUrls();
  const translations = useTranslations();
  const { config } = useProviderSearchContext();
  const { isMobile } = useMedia();
  return (
    <LogoWrapper to={goToListing()}>
      <Image
        style={{ height: isMobile ? sizing(6) : sizing(7) }}
        path={isMobile ? config.logos.mobile : config.logos.default}
        alt={translations.providersearch.listingPage.accessibility.altTextLogo}
      />
    </LogoWrapper>
  );
};

export function Header({ filtersDrawerOpen }: { filtersDrawerOpen: boolean }) {
  const translations = useTranslations();

  return (
    <AppBarProviderSearch filtersDrawerOpen={filtersDrawerOpen}>
      <LeftPart>
        <SkipLink />
        <ProviderSearchLogo />
      </LeftPart>
      <nav
        aria-label={translations.providersearch.header.mainNavigationAriaLabel}
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <NavBarActions />
      </nav>
    </AppBarProviderSearch>
  );
}
