import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MessageIcon from "@mui/icons-material/Message";
import { SvgIconProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BLUR_ALL, BLUR_LAST, TRACK_EVENTS } from "@recare/core/consts";
import { EVENTS } from "@recare/core/consts/events";
import { useDateLocale } from "@recare/core/hooks/useDateLocale";
import { isAppEmbedded } from "@recare/core/model/utils/featureFlags";
import { getKey } from "@recare/core/model/utils/strings";
import {
  Event,
  Filev1,
  LastSeen,
  QueryProgress,
  SearchType,
} from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { formatRelative, fromUnixTime } from "date-fns";
import { IconCircle } from "ds/components/Chip";
import LinkComponent from "ds/components/Link";
import { CARD_BORDER, GREY_300, GREY_600 } from "ds/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { border, dp, margin, padding } from "ds/materials/metrics";
import {
  Body,
  Caption,
  FONT_SIZE_12,
  FONT_SIZE_14,
  FONT_WEIGHT_SEMI_BOLD,
  LINE_HEIGHT_16,
  LINE_HEIGHT_20,
  LINE_HEIGHT_24,
} from "ds/materials/typography";
import { EventSpecMessage, EventSpecs } from "dsl/atoms/EventSpecs";
import { SeenTag } from "dsl/atoms/SeenTag";
import { EventMessage } from "dsl/molecules/Messenger/EventCard/EventMessagingBubble";
import { EventMessagingBubbleV2 } from "dsl/molecules/Messenger/EventCard/EventMessagingBubbleV2";
import { PrintableEventCard } from "dsl/molecules/Messenger/PrintMessenger";
import {
  BANNER_WIDTH,
  CancelButton,
  DownloadButton,
} from "dsl/organisms/Banners";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { CSSProperties, ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";

export type CardFormat = {
  blurType?: number;
  lastSeens?: LastSeen[] | null | undefined;
  seen?: boolean | null | undefined;
  seenTimestamp?: number | null | undefined;
  variant?: EventSpecs["variant"];
};

export type EventCardProps = {
  Icon?: ComponentType<SvgIconProps> | null;
  activateFilesFeature: boolean;
  cancelAction: (() => void) | null;
  cardFormat: CardFormat;
  eventMessages?: EventSpecMessage[];
  file?: Filev1 | null;
  isDividerVisible: boolean;
  isEventUserInitiated?: boolean;
  messages: string[] | null;
  onSubmitQueryProgress?: QueryProgress;
  patientId?: string;
  print?: boolean;
  searchType?: SearchType | null;
  sentByLabel?: string | null;
  shouldShowBefore: boolean;
  statusHeader?: string;
  testId?: string;
  timestamp?: number | null;
  type: Event["type"] | undefined;
};

type TitleLayerProps = Pick<
  EventCardProps,
  "cardFormat" | "sentByLabel" | "statusHeader" | "timestamp"
> & {
  right: boolean | undefined;
};

export const DEFAULT_ICON = MessageIcon;
export const SUCCESS_ICON = DoneIcon;
export const ERROR_ICON = CloseIcon;
export const PENDING_ICON = CachedIcon;

export const ContentContainer = styled.div<{
  big?: boolean;
}>`
  min-height: ${({ big }) => (big ? dp(65) : dp(5))};
  margin: ${margin(1, 0, 0, 0)};
  display: flex;
  flex-grow: 1;
  padding: ${padding(0, 0, 2, 0)};
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
`;

export const TitleContainer = styled.div<{
  right?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
`;

const EventCardContainer = styled.li<{
  isDividerVisible: boolean;
  shouldShowBefore: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${margin(0, 2)};

  ${({ isDividerVisible, shouldShowBefore }) =>
    shouldShowBefore &&
    `&::before {
      content: "";
      width: ${dp(0)};
      height: ${dp(isDividerVisible ? 48 : 16)};
      border-left: ${dp(1)} solid;
      border-color: ${isDividerVisible ? GREY_300 : "transparent"};
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }`}
`;

const VerticalContainer = styled.div<{
  order: CSSProperties["order"];
  right?: boolean;
}>`
  align-items: ${({ right }) => (right ? "flex-end" : "flex-start")};
  display: flex;
  flex-direction: column;
  order: ${({ order }) => order};
  width: 84%;
`;

const SeenFooter = styled(Caption)<{
  alignItems: CSSProperties["alignItems"];
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems ?? "flex-end"};
  margin: 0;
`;

const SpaceY = styled.div<{
  margin?: CSSProperties["margin"];
  marginChildren?: CSSProperties["margin"];
  right: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ margin: m }) => m};

  & * + * {
    margin: ${({ marginChildren: m }) => m || margin(0.5, 0, 0, 0)};
  }
`;

const getBlur = (blurType: number | undefined, isLast: boolean) => {
  switch (blurType) {
    case BLUR_ALL:
      return true;
    case BLUR_LAST:
      return isLast;
    default:
      return false;
  }
};

function IconItem({
  Icon,
  style,
  variant,
}: {
  Icon: ComponentType<SvgIconProps>;
  style?: CSSProperties;
  variant?: EventSpecs["variant"];
}) {
  const theme = useTheme();

  return (
    <VerticalLayout style={style}>
      <IconCircle
        border={border({ color: CARD_BORDER })}
        backgroundColor={theme.palette.grey["50"]}
      >
        <Icon
          style={{
            width: dp(20),
          }}
          htmlColor={
            theme.palette[variant === "error" ? variant : "primary"].main
          }
        />
      </IconCircle>
    </VerticalLayout>
  );
}

const TextHack = styled.div<{
  right?: boolean;
}>`
  display: flex;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
  width: 100%;
`;

function TitleLayer({
  cardFormat,
  right,
  sentByLabel,
  statusHeader,
  timestamp,
}: TitleLayerProps) {
  const { variant } = cardFormat;
  const { currentLocale: locale } = useDateLocale();

  return (
    <TitleContainer data-testid="event_card_header" right={right}>
      <VerticalContainer right={right} order={1}>
        <HorizontalLayout aligned>
          {!right && (
            <>
              {sentByLabel && (
                <>
                  <Typography
                    color={GREY_600}
                    fontSize={FONT_SIZE_12}
                    lineHeight={LINE_HEIGHT_16}
                  >
                    {sentByLabel}
                  </Typography>
                </>
              )}
              {sentByLabel && timestamp && (
                <span
                  style={{
                    display: "flex",
                    color: GREY_600,
                    fontSize: FONT_SIZE_12,
                  }}
                >
                  &nbsp; &#x2022; &nbsp;
                </span>
              )}
            </>
          )}
          {timestamp && (
            <Typography
              color={GREY_600}
              fontSize={FONT_SIZE_12}
              lineHeight={LINE_HEIGHT_16}
            >
              {formatRelative(fromUnixTime(timestamp), new Date(), {
                locale,
              })}
            </Typography>
          )}
        </HorizontalLayout>
        {statusHeader && (
          <TextHack right={right}>
            <Body
              as="p"
              textAlign={right ? "right" : "left"}
              {...(variant ? { [variant]: true } : {})}
              whiteSpace="pre-wrap"
              margin={margin(0)}
              fontWeight={FONT_WEIGHT_SEMI_BOLD}
              color={GREY_600}
            >
              {statusHeader}
            </Body>
          </TextHack>
        )}
      </VerticalContainer>
    </TitleContainer>
  );
}

export function EventCardMessage({
  activateFilesFeature,
  cancelMutationProgress,
  cardFormat,
  eventMessages,
  file,
  handleCancel,
  Icon,
  isDividerVisible,
  isEventUserInitiated,
  messages,
  patientId,
  searchType,
  shouldShowBefore,
  statusHeader,
  testId,
  timestamp,
}: Pick<
  EventCardProps,
  | "shouldShowBefore"
  | "isDividerVisible"
  | "activateFilesFeature"
  | "cardFormat"
  | "eventMessages"
  | "file"
  | "Icon"
  | "isEventUserInitiated"
  | "messages"
  | "searchType"
  | "statusHeader"
  | "timestamp"
  | "patientId"
> & {
  cancelMutationProgress: QueryProgress | undefined;
  handleCancel: (() => void) | null;
  testId?: string;
}) {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { currentLocale: locale } = useDateLocale();
  const { blurType } = cardFormat;
  const formattedTimestamp = timestamp
    ? formatRelative(fromUnixTime(timestamp), new Date(), { locale })
    : null;

  return (
    <EventCardContainer
      data-testid={testId}
      isDividerVisible={isDividerVisible}
      shouldShowBefore={shouldShowBefore}
    >
      <HorizontalLayout
        data-testid="event_card_header"
        justify="center"
        overflow="visible"
        width="100%"
        zIndex="1"
      >
        <VerticalLayout
          aligned
          border={border({ color: CARD_BORDER })}
          boxSizing="border-box"
          overflow="visible"
          padding={padding(Icon ? 0 : 1, 2, 1, 2)}
          width="85%"
          maxWidth={dp(BANNER_WIDTH)}
        >
          {Icon && (
            <IconItem
              Icon={Icon}
              variant={cardFormat.variant}
              style={{ marginTop: dp(-20) }}
            />
          )}
          <VerticalLayout aligned padding={padding(1)}>
            <div>
              {formattedTimestamp && (
                <Typography
                  color={GREY_600}
                  fontSize={FONT_SIZE_12}
                  lineHeight={LINE_HEIGHT_16}
                >
                  {formattedTimestamp}
                </Typography>
              )}
            </div>
            <HorizontalLayout>
              {statusHeader && (
                <Typography
                  fontSize={FONT_SIZE_14}
                  lineHeight={LINE_HEIGHT_24}
                  whiteSpace="normal"
                  textAlign="center"
                  sx={{
                    color:
                      cardFormat.variant === "success"
                        ? "primary.main"
                        : "common.black",
                  }}
                >
                  {statusHeader}
                </Typography>
              )}
            </HorizontalLayout>
            {eventMessages?.map(
              ({ date, link, reasons, time, title }, index) => (
                <VerticalLayout
                  key={getKey(index)}
                  boxSizing="border-box"
                  width="100%"
                  margin={margin(1, 0, 0, 0)}
                  aligned
                >
                  <Typography
                    fontSize={FONT_SIZE_14}
                    lineHeight={LINE_HEIGHT_20}
                    textAlign="center"
                    sx={{ color: "grey.700", wordBreak: "break-word" }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    fontSize={FONT_SIZE_14}
                    lineHeight={LINE_HEIGHT_20}
                    textAlign="center"
                    sx={{ color: "grey.700", wordBreak: "break-word" }}
                  >
                    {date}
                  </Typography>
                  <Typography
                    fontSize={FONT_SIZE_14}
                    lineHeight={LINE_HEIGHT_20}
                    textAlign="center"
                    sx={{ color: "grey.700", wordBreak: "break-word" }}
                  >
                    {time}
                  </Typography>
                  {reasons?.map((reason, i) => {
                    return (
                      <Typography
                        key={getKey(reason, i)}
                        fontSize={FONT_SIZE_14}
                        lineHeight={LINE_HEIGHT_20}
                        textAlign="center"
                        sx={{
                          color: "grey.700",
                          wordBreak: "break-word",
                        }}
                      >
                        {reason}
                      </Typography>
                    );
                  })}
                  {file && (
                    <DownloadButton file={file} right={isEventUserInitiated} />
                  )}
                  {activateFilesFeature && link && (
                    <LinkComponent
                      fontSize={FONT_SIZE_14}
                      Icon={KeyboardArrowRightIcon}
                      onAction={() => {
                        trackEvent({
                          name: TRACK_EVENTS.MESSENGER_SEE_DOCUMENT_LINK,
                        });
                        navigate(link.to);
                      }}
                      value={link.label}
                    />
                  )}
                </VerticalLayout>
              ),
            )}
            <HorizontalLayout>
              {!isAppEmbedded() && handleCancel && (
                <CancelButton
                  onCancel={handleCancel}
                  withButton
                  onSubmitQueryProgress={cancelMutationProgress}
                />
              )}
            </HorizontalLayout>
          </VerticalLayout>
        </VerticalLayout>
      </HorizontalLayout>
      <HorizontalLayout
        boxSizing="border-box"
        width="100%"
        margin={margin(0, 0, 0, 0)}
        overflow="visible"
      >
        <SpaceY
          right={!!isEventUserInitiated}
          margin={messages?.length ? margin(1, 0, 0, 0) : "0"}
        >
          {messages?.map((message, index) => (
            <EventMessagingBubbleV2
              blur={getBlur(blurType, index === messages?.length - 1)}
              isMyMessage={!!isEventUserInitiated}
              key={index.toString()}
              message={message}
            />
          ))}
          {messages && isEventUserInitiated && (
            <SeenFooter alignItems="flex-end">
              <SeenTag
                patientId={patientId}
                searchType={searchType}
                lastSeens={cardFormat.lastSeens}
              />
            </SeenFooter>
          )}
        </SpaceY>
      </HorizontalLayout>
    </EventCardContainer>
  );
}

export default function EventCard({
  activateFilesFeature,
  cancelAction,
  cardFormat,
  eventMessages,
  file,
  Icon,
  isDividerVisible,
  isEventUserInitiated,
  messages,
  onSubmitQueryProgress,
  patientId,
  print,
  searchType,
  sentByLabel,
  shouldShowBefore,
  statusHeader,
  testId,
  timestamp,
  type,
}: EventCardProps) {
  const notify = useNotification();
  const translations = useTranslations();
  const { blurType, lastSeens } = cardFormat;

  async function handleCancel() {
    if (!cancelAction) return;

    try {
      await cancelAction();
      notify({
        message: translations.actions.undoActionCompletion,
      });
    } catch (err) {
      console.error(err);
      notify({
        message: translations.auctionRequest.uploadError,
      });
    }
  }

  if (print) {
    return (
      <PrintableEventCard
        eventMessages={eventMessages}
        messagesToDisplay={messages}
        statusHeader={statusHeader}
        timestamp={timestamp}
      />
    );
  }

  if (type !== EVENTS.ENCRYPTED_MESSAGE) {
    return (
      <EventCardMessage
        activateFilesFeature={activateFilesFeature}
        cancelMutationProgress={onSubmitQueryProgress}
        cardFormat={cardFormat}
        eventMessages={eventMessages}
        file={file}
        handleCancel={cancelAction ? handleCancel : null}
        Icon={Icon}
        isDividerVisible={isDividerVisible}
        isEventUserInitiated={isEventUserInitiated}
        messages={messages}
        patientId={patientId}
        searchType={searchType}
        shouldShowBefore={shouldShowBefore}
        statusHeader={statusHeader}
        testId={testId}
        timestamp={timestamp}
      />
    );
  }

  return (
    <EventCardContainer
      isDividerVisible={isDividerVisible}
      shouldShowBefore={shouldShowBefore}
    >
      <TitleLayer
        cardFormat={cardFormat}
        right={isEventUserInitiated}
        sentByLabel={sentByLabel}
        statusHeader={statusHeader}
        timestamp={timestamp}
      />
      <ContentContainer>
        <EventMessage
          eventMessages={eventMessages}
          right={isEventUserInitiated}
        />
        {messages?.map((message, index) => (
          <EventMessagingBubbleV2
            blur={getBlur(blurType, index === messages?.length - 1)}
            isMyMessage={!!isEventUserInitiated}
            key={index.toString()}
            message={message}
          />
        ))}
        {messages && isEventUserInitiated && (
          <SeenFooter alignItems="flex-end">
            <SeenTag
              patientId={patientId}
              searchType={searchType}
              lastSeens={lastSeens}
            />
          </SeenFooter>
        )}
        {file && <DownloadButton file={file} right={isEventUserInitiated} />}
        {!isAppEmbedded() && cancelAction && (
          <CancelButton
            onCancel={handleCancel}
            withButton
            onSubmitQueryProgress={onSubmitQueryProgress}
          />
        )}
      </ContentContainer>
    </EventCardContainer>
  );
}
