import {
  GLOSSARY_SECTION_CONSULTANTS,
  PROVIDER_SEARCH_STATE_MAP,
} from "@recare/core/consts";
import { pathToUrl } from "@recare/core/model/utils/urls";
import { GlossarySection } from "@recare/core/types";
import { ProviderSearchContext } from "context/ProviderSearchContext";
import { ProviderSearchFilters } from "dsl/organisms/Filters/ProviderSearchApp/types";
import { USER_FILTER_TREATMENT_LOCATION } from "dsl/organisms/Filters/consts";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import { Location, NavigateFunction } from "react-router-dom";

export const getProviderSearchNavigationUrls = ({
  location,
  providerSearchContext,
}: {
  location: Location;
  providerSearchContext: ProviderSearchContext;
}) => {
  const { search } = location;
  const { providerSearch } = providerSearchContext;
  const state = PROVIDER_SEARCH_STATE_MAP[providerSearch];

  const urls = {
    goToOnboardingFunnel(searchString?: string) {
      return pathToUrl({
        pathname: `/providersearch/${state}`,
        search: searchString ?? "",
      });
    },
    goToListing() {
      return pathToUrl({
        pathname: `/providersearch/${state}/listing`,
        search,
      });
    },
    goToListingPrint({ filters }: { filters: ProviderSearchFilters }) {
      const query = `?${toFilterQueryString(filters)}&print`;
      return pathToUrl({
        pathname: `/providersearch/${state}/printable-listing`,
        search: query,
      });
    },
    goToSearchDashboard(id?: number) {
      let patientId = id;
      // if no patient is passed to travel to the first available patient id
      if (!patientId) {
        patientId =
          providerSearchContext.patients.active?.[0]?.id ??
          providerSearchContext.patients.archived?.[0]?.id;
      }

      return pathToUrl({
        pathname: `/providersearch/${state}/dashboard/patient/${patientId}`,
        search,
      });
    },
    goToRequest(id: number, tabName?: string) {
      return pathToUrl({
        pathname: `/providersearch/${state}/request/${id}`,
        search: tabName ? `${search}&activeTab=${tabName}` : search,
      });
    },
    goToProvider({
      careproviderId,
      zipcode,
    }: {
      careproviderId: number;
      zipcode?: string;
    }) {
      const searchString = zipcode
        ? `${search}${
            search.includes("?") ? "&" : "?"
          }${USER_FILTER_TREATMENT_LOCATION}=${zipcode}`
        : search;

      return pathToUrl({
        pathname: `/providersearch/${state}/provider/${careproviderId}`,
        search: searchString,
      });
    },
    goToConsultant({ consultantId }: { consultantId: number }) {
      return pathToUrl({
        pathname: `/providersearch/${state}/consultant/${consultantId}`,
        search,
      });
    },
    goToProviderPrint({ careproviderId }: { careproviderId: number }) {
      return pathToUrl({
        pathname: `/providersearch/${state}/print-provider/${careproviderId}`,
        search: search ? `${search}&print` : "print",
      });
    },
    goToConsultantPrint({ consultantId }: { consultantId: number }) {
      return pathToUrl({
        pathname: `/providersearch/${state}/print-consultant/${consultantId}`,
        search: search ? `${search}&print` : "print",
      });
    },
    goToAccount({ accountId }: { accountId: number }) {
      return pathToUrl({
        pathname: `/providersearch/${state}/account/${accountId}`,
        search,
      });
    },
    goToTableOfContents() {
      return pathToUrl({
        pathname: `/providersearch/${state}/tableofcontents`,
        search,
      });
    },
    goToGlossary(
      section: GlossarySection | undefined = GLOSSARY_SECTION_CONSULTANTS,
    ) {
      return pathToUrl({
        pathname: `/providersearch/${state}/glossary/${section}`,
        search,
      });
    },
  };

  return urls;
};

export const getProviderSearchNavigationHandlers = ({
  location,
  navigate,
  providerSearchContext,
}: {
  location: Location;
  navigate: NavigateFunction;
  providerSearchContext: ProviderSearchContext;
}) => {
  const urls = getProviderSearchNavigationUrls({
    location,
    providerSearchContext,
  });

  return {
    goToOnboardingFunnel(searchString?: string) {
      navigate(urls.goToOnboardingFunnel(searchString));
    },
    goToListing() {
      navigate(urls.goToListing());
    },
    goToSearchDashboard(id?: number) {
      navigate(urls.goToSearchDashboard(id));
    },
    goToRequest(id: number, tabName?: string) {
      navigate(urls.goToRequest(id, tabName));
    },
    goToProvider({
      careproviderId,
      zipcode,
    }: {
      careproviderId: number;
      zipcode?: string;
    }) {
      navigate(urls.goToProvider({ careproviderId, zipcode }));
    },
    goToConsultant({ consultantId }: { consultantId: number }) {
      navigate(urls.goToConsultant({ consultantId }));
    },
    goToAccount({ accountId }: { accountId: number }) {
      navigate(urls.goToAccount({ accountId }));
    },
    goToTableOfContents() {
      navigate(urls.goToTableOfContents());
    },
    goToGlossary(section?: GlossarySection) {
      navigate(urls.goToGlossary(section));
    },
  };
};
