import { visuallyHidden } from "@mui/utils";
import { TRACK_EVENTS } from "@recare/core/consts";
import { FilterValueStartDate } from "@recare/core/types";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  validateModel,
  valueDef,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { addDays, addWeeks, getUnixTime } from "date-fns";
import RSButton from "ds/components/RSButton";
import ConnectedRadioChipGroup, {
  RadioChipGroupProps,
} from "ds/components/RadioChipGroup";
import { margin, padding, sizing } from "ds/materials/metrics";
import { Body, FONT_SIZE_20 } from "ds/materials/typography";
import { Iterable } from "immutable";
import { useTracking } from "react-tracking";
import {
  FormStepWrapper,
  FunnelFormData,
  FunnelSteps,
  STEP_SERVICES,
  STEP_START_DATE,
  getAccessibleTitleWithStep,
} from "../common";
import { usePageMetaData } from "dsl/hooks";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";

const TODAY = getUnixTime(new Date());
const THREE_DAYS = getUnixTime(addDays(new Date(), 3));
const TWO_WEEKS = getUnixTime(addWeeks(new Date(), 2));

const getTrackingTranslation = (date: number | undefined) => {
  switch (date) {
    case TODAY:
      return "As soon as possible";
    case THREE_DAYS:
      return "In a few days";
    case TWO_WEEKS:
      return "In a few weeks";
    default:
      return undefined;
  }
};

const modelDefinition = convertModelDefinition({
  ...valueDef("start_date", {
    convertOut: (output) => {
      if (!output) return null;
      const outputValue = Iterable.isIterable(output) ? output.toJS() : output;
      return outputValue;
    },
  }),
});

export function StartDate({
  formData,
  onSubmit,
  setCurrentStep,
  setFormData,
}: {
  formData: FunnelFormData;
  onSubmit: (data: FunnelFormData) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<FunnelSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<FunnelFormData>>;
}) {
  const { trackEvent } = useTracking();
  const translations = useTranslations();

  usePageMetaData({
    title: (translations) =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.startTimeTitle,
      ),
    description: (translations) =>
      translations.providersearch.pageTitles.startTimeDescription,
  });

  const options: RadioChipGroupProps["options"] = [
    {
      name: translations.providersearch.onboardingFunnel.fourthStep.asap,
      value: TODAY,
    },
    {
      name: translations.providersearch.onboardingFunnel.fourthStep.days,
      value: THREE_DAYS,
    },
    {
      name: translations.providersearch.onboardingFunnel.fourthStep.weeks,
      value: TWO_WEEKS,
    },
  ];

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={formData}
      modelDefinition={modelDefinition}
      onSubmit={({
        start_date,
      }: {
        start_date: FilterValueStartDate | undefined;
      }) => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_START_DATE_SELECTED,
          start_date: start_date,
          selection: getTrackingTranslation(start_date),
        });
        onSubmit({ start_date });
      }}
      validate={validateModel(modelDefinition)}
    >
      {({ submit }) => {
        return (
          <FormStepWrapper
            formData={formData}
            testId="start_date-select-wrapper"
            title={
              translations.providersearch.onboardingFunnel.fourthStep.question
            }
            step={STEP_START_DATE}
          >
            <>
              <ConnectedRadioChipGroup
                chipStyle={{
                  height: sizing(6),
                  justifyContent: "center",
                  width: "100%",
                }}
                elementName="start_date"
                formControlSx={{ padding: padding(0, 3) }}
                formLabelSx={{
                  fontSize: FONT_SIZE_20,
                  margin: margin(0, 0, 2),
                  ...visuallyHidden,
                }}
                label={getAccessibleTitleWithStep({
                  step: STEP_START_DATE,
                  title:
                    translations.providersearch.onboardingFunnel.fourthStep
                      .question,
                  translations,
                })}
                options={options}
                radioGroupSx={{
                  flexDirection: "column",
                  margin: margin(1, 0),
                }}
                squared
                id="start_date"
              />
              <Body
                as="p"
                style={{ padding: padding(1, 3) }}
                margin={margin(0)}
              >
                {
                  translations.providersearch.onboardingFunnel
                    .textDontKnowStartSearch
                }
              </Body>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: padding(3, 0, 2),
                }}
              >
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                    padding: padding(0, 3),
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="go-back-on-startdate-step"
                  loading="na"
                  type="button"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      start_date: undefined,
                    });
                    setCurrentStep(STEP_SERVICES);
                  }}
                  variant="outlined"
                >
                  {translations.providersearch.onboardingFunnel.backButton}
                </RSButton>
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                    padding: padding(0, 3),
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="confirm-startdate"
                  loading="na"
                  type="submit"
                  onClick={submit}
                  variant="contained"
                >
                  {
                    translations.providersearch.onboardingFunnel
                      .startSearchButton
                  }
                </RSButton>
              </div>
            </>
          </FormStepWrapper>
        );
      }}
    </SimpleFormRenderProps>
  );
}
