import { BORDER_COLOR, INFO_CARD_BORDER_BOTTOM } from "ds/materials/colors";
import { VerticalLayout } from "ds/materials/layouts";
import { border, dp, margin, padding, sizing } from "ds/materials/metrics";
import { Body, Subheading, Title } from "ds/materials/typography";
import { CSSProperties } from "react";
import styled from "styled-components";

export const DialogTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ElementsWrapper = styled.div<{
  isFirst?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  padding: ${({ isFirst }) =>
    isFirst ? padding(5.5, 2, 1, 2) : padding(0, 2, 1, 2)};
  max-width: ${dp(100)};
  border-right: ${border({ color: BORDER_COLOR })};
  @media print {
    max-width: ${dp(50)};
    padding: 0;
  }
`;

export const ResultWrapper = styled(ElementsWrapper)`
  padding: ${padding(1, 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ElementsRow = styled.div<{ isLast?: boolean }>`
  display: flex;
  padding: ${padding(0, 1)};
  border-bottom: ${({ isLast }) =>
    isLast ? border({ color: BORDER_COLOR }) : "none"};
  @media print {
    padding: 0;
  }
`;

export const LabelWrapper = styled.div<{
  columnWidth: number;
  valuesNumber: number;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ columnWidth, valuesNumber }) =>
    valuesNumber ? `calc(100% - ${dp(valuesNumber * columnWidth)})` : "auto"};
  padding: ${padding(0, 0, 1, 0)};
  @media print {
    padding: 0;
  }
`;

export const RehabElementWrapper = styled(VerticalLayout)`
  justify-content: center;
`;

export const TobBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 10%;
  @media print {
    display: none;
  }
`;

export const BarthelTable = styled.table`
  page-break-inside: auto;
  border-collapse: collapse;
  width: 80%;
  @media print {
    width: 100%;
    margin: 0;
  }
`;

export const BarthelTableRow = styled.tr`
  page-break-inside: avoid;
`;

export const BarthelDividerRow = styled.tr`
  border-bottom: ${border({ color: INFO_CARD_BORDER_BOTTOM })};
  > td {
    width: 100%;
    height: ${dp(5)};
  }
  @media print {
    > td {
      width: 100%;
      height: ${dp(1)};
    }
  }
`;

export const BarthelWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${sizing(5)};
  @media print {
    @page {
      margin: 0;
    }
    margin-bottom: 0;
    font-size: ${dp(13)};
  }
`;

export const BarthelSummaryTitle = styled(Title)<{ print?: boolean }>`
  display: ${({ print }) => (print ? "none" : "block")};
  @media print {
    font-size: ${dp(13)};
    margin: ${margin(0, 0, 0, 1)};
    padding: 0;
    display: ${({ print }) => (print ? "block" : "none")};
  }
`;

export const BarthelBody2Desktop = styled(Body)`
  @media print {
    font-size: ${dp(11)};
    margin: 0;
    padding: ${padding(0, 0.5)};
    line-height: ${dp(18)};
  }
`;

export const BarthelResult = styled(Body)`
  font-size: ${dp(20)};
  @media print {
    font-size: ${dp(13)};
    margin: 0;
    line-height: ${dp(18)};
  }
`;

export const BarthelSubheading2Desktop = styled(Subheading)`
  @media print {
    margin: 0;
    height: 0;
    line-height: ${dp(18)};
  }
`;
export const TitleSubheading2Desktop = styled(Subheading)`
  @media print {
    font-size: ${dp(12)};
    margin: 0;
    padding: 0;
    line-height: ${dp(18)};
  }
`;

export const ReturnBarRef = styled.div`
  @media print {
    display: none;
  }
`;

export const DisplayWrapper = styled.div`
  @media print {
    margin: 0;
  }
`;

export const BarthelTableCell = styled.td<{
  borderRight?: CSSProperties["borderColor"];
  height?: CSSProperties["height"];
  noBorder?: boolean;
  textAlign?: CSSProperties["textAlign"];
}>`
  page-break-inside: avoid;
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  border-right: ${({ borderRight }) => border({ color: borderRight })};
  &:not(:last-child) {
    border-right: ${({ noBorder }) =>
      noBorder ? undefined : border({ color: INFO_CARD_BORDER_BOTTOM })};
  }
  height: ${({ height }) => (height ? height : undefined)};
  @media print {
    height: ${dp(1.25)};
    margin: 0;
    padding: 0;
  }
`;

export const ReturnBarWrapper = styled.div`
  margin: ${margin(2, 3)};
  @media print {
    display: none;
  }
`;

export const CheckBoxWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${padding(2)};
  border-right: ${border({ color: BORDER_COLOR })};
  width: ${dp(100)};
  min-width: ${dp(100)};
  @media print {
    padding: 0;
  }
`;
