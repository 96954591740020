import { Paper, styled, useTheme } from "@mui/material";
import { QUERY_PROGRESS_SUCCEED } from "@recare/core/consts";
import { useDateLocale } from "@recare/core/hooks/useDateLocale";
import { formatUnixDate } from "@recare/core/model/utils/dates";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import { ProviderSearchListing } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { useGetProviderSearchListing } from "apollo/hooks/queries";
import { getUnixTime } from "date-fns";
import GeneralTable, { TableIntro } from "ds/components/Tables/GeneralTable";
import {
  columnProviderSearchCapacity,
  columnProviderSearchFacility,
  columnProviderSearchLocation,
  columnProviderSearchName,
} from "ds/components/Tables/GeneralTable/CustomCells/PrintableProviderSearchListing";
import { margin } from "ds/materials/metrics";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { usePageMetaData } from "dsl/hooks";
import {
  getProviderSearchTitle,
  useProviderSearchFilterContext,
} from "dsl/molecules/ProviderSearchAppWrapper";

const StyledTablePaper = styled(Paper)`
  @media print {
    margin: ${margin(0, 2)};
    box-shadow: none !important;
  }
`;

const PrintListing = ({
  providerListing,
}: {
  providerListing: ProviderSearchListing | undefined;
}) => {
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const theme = useTheme();
  const { currentLocale: locale } = useDateLocale();

  usePrintWindow();

  if (!providerListing?.cards?.length) return null;

  return (
    <StyledTablePaper>
      <TableIntro title={translations.providersearch.listingPage.printTitle} />
      <GeneralTable
        id="provider-listing"
        emptyComponent={null}
        data={providerListing.cards}
        columns={[
          columnProviderSearchName(),
          columnProviderSearchLocation({ translations }),
          columnProviderSearchFacility({ translations, getOntology }),
          columnProviderSearchCapacity({ locale, theme, translations }),
        ]}
      />
    </StyledTablePaper>
  );
};

export function PrintableProviderSearchListing() {
  const { filters } = useProviderSearchFilterContext();
  const translations = useTranslations();
  usePageMetaData({
    title: () =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.printListingPageTitle({
          date: formatUnixDate(getUnixTime(new Date()), "de"),
        }),
      ),
  });

  const [queryProgress, providerListing] = useGetProviderSearchListing({
    filters,
  });

  if (queryProgress !== QUERY_PROGRESS_SUCCEED) return null;

  return <PrintListing providerListing={providerListing} />;
}
