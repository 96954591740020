import {
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
} from "@mui/material";
import { AriaListBoxOptions, useListBox, useOption } from "@react-aria/listbox";
import { ComboBoxState } from "@react-stately/combobox";
import { ListState } from "@react-stately/list";
import type { Node } from "@react-types/shared";
import { KEYBOARD_FOCUS_OUTLINE } from "ds/materials/colors";
import { dp } from "ds/materials/metrics";
import { useRef } from "react";

type OptionProps = {
  item: Node<object>;
  state: ListState<object>;
} & ListItemButtonProps;

type ListBoxProps = AriaListBoxOptions<unknown> & {
  listBoxRef: React.RefObject<HTMLUListElement>;
  noOptionsText?: string;
  state: ComboBoxState<object>;
};

export function ListBox(props: ListBoxProps) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <List {...listBoxProps} ref={listBoxRef}>
      {state.collection.size === 0 && !!props.noOptionsText ? (
        <Option
          disabled
          item={
            {
              key: -999,
              rendered: props.noOptionsText,
            } as Node<object>
          }
          key={-999}
          state={state}
          sx={{
            "&.Mui-disabled": {
              opacity: 0.9,
            },
          }}
        />
      ) : (
        [...state.collection].map((item) => (
          <Option key={item.key} item={item} state={state} />
        ))
      )}
    </List>
  );
}

function Option({ item, state, ...props }: OptionProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isFocused, isSelected, optionProps } = useOption(
    { key: item.key },
    state,
    ref,
  );

  return (
    <ListItemButton
      {...optionProps}
      {...props}
      dense
      divider
      ref={ref}
      selected={isFocused || isSelected}
      sx={{
        ...props.sx,
        boxSizing: "border-box",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        ...(isFocused
          ? {
              outlineOffset: dp(-2),
              outline: `${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE}`,
            }
          : {}),
      }}
    >
      <ListItemText>{item.rendered}</ListItemText>
    </ListItemButton>
  );
}
