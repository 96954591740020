import { PROVIDER_SEARCH_STATE_MAP } from "@recare/core/consts";
import {
  PROVIDER_SEARCH_OVERWEIGHT,
  PSEUDO_SERVICE_OVERWEIGHT,
} from "@recare/core/model/providerSearch";
import { FilterValueServices, FilterValueSolutions } from "@recare/core/types";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import { PSEUDO_SERVICE_OTHER } from "dsl/ecosystems/ProviderSearchOnboarding/Pages/Services";
import { PSEUDO_SOLUTION_OTHER } from "dsl/ecosystems/ProviderSearchOnboarding/Pages/Solutions";
import { FunnelFormData } from "dsl/ecosystems/ProviderSearchOnboarding/common";
import {
  USER_FILTER_SERVICES,
  USER_FILTER_SOLUTIONS,
} from "dsl/organisms/Filters/consts";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import { ZIPCODE_REDIRECT_PARAM } from "entries/providersearch/Router";
import { useSearchParams } from "react-router-dom";

const redirectLinkSanitizer = (formData: FunnelFormData) => {
  Object.entries(formData).forEach(([key, value]) => {
    switch (key) {
      case USER_FILTER_SOLUTIONS:
        if (!value) return null;
        formData[key] = (value as FilterValueSolutions).filter(
          (item) => ![PSEUDO_SOLUTION_OTHER].includes(item),
        );
        break;
      case USER_FILTER_SERVICES:
        if (!value) return null;
        formData[key] = (value as FilterValueServices)?.filter(
          (item) =>
            ![PSEUDO_SERVICE_OTHER, PSEUDO_SERVICE_OVERWEIGHT].includes(item),
        );

        if (
          (value as FilterValueServices).includes(PSEUDO_SERVICE_OVERWEIGHT)
        ) {
          formData.weight_interval = {
            min: PROVIDER_SEARCH_OVERWEIGHT,
            max: undefined,
          };
        }

        break;
      default:
        break;
    }
  });
  return formData;
};

export const useGetProviderSearchRedirectLink = () => {
  const [search] = useSearchParams();
  const { providerSearch } = useProviderSearchContext();
  const state = PROVIDER_SEARCH_STATE_MAP[providerSearch];
  const redirect =
    search.get(ZIPCODE_REDIRECT_PARAM) ?? `/providersearch/${state}/listing`;

  const getFilterLink = ({ formData }: { formData?: FunnelFormData }) => {
    if (!formData) return redirect;
    const updatedFormData = { ...formData };
    const sanitizedFormData = redirectLinkSanitizer(updatedFormData);
    const link = `${redirect}${
      redirect.includes("?") ? "&" : "?"
    }${toFilterQueryString(sanitizedFormData)}`;
    return link;
  };
  return getFilterLink;
};
